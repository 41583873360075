<script setup lang="ts">
import type { ProductBadge } from 'types/models/product'
import { capitalizeSentence } from 'lib/strings'

interface Props {
  badge: ProductBadge
  isFirstIndex: boolean
}

const props = defineProps<Props>()

const { $t } = useNuxtApp()

const badgeText = computed(() => {
  const text = $t(`badge-${props.badge.name}`, { percent: `${props.badge.value}%` })

  return capitalizeSentence(text)
})
</script>

<template>
  <span
    v-if="!isFirstIndex"
    class="bullet"
  />
  <span
    v-bind="$attrs"
    :class="`badge ${badge.name}`"
  >{{ badgeText }}</span>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/body';

.bullet {
  width: 0.3rem;
  height: 0.3rem;
  aspect-ratio: 1/1;
  background-color: var(--black);
  border-radius: 5rem;
}

.badge {
  @include caption2;
  font-size: 1.3rem;

  &.last-chance,
  &.low-stock {
    color: var(--orange);
  }
}

@media screen and (min-width: $phone-bigger) {
  .bullet {
    display: block;
  }
}
</style>
