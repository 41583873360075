<script lang="ts" setup>
const props = defineProps<{
  productSku: string | undefined
  onForceSelection: () => void

}>()

const emit = defineEmits(['showIframe'])

const {
  public: { BwpSiteId, BwpWidgetId,
  },
} = useRuntimeConfig()

let observer: MutationObserver | null = null

onMounted(() => {
  setTimeout(() => {
    const targetNode: Element | null = document.querySelector('#bwpFrame')
    const config = { attributes: true }

    function emitShowIframe(mutationList: MutationRecord[]) {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes') {
          if (targetNode && targetNode.clientHeight !== undefined) {
            const isIframeVisible = targetNode.clientHeight > 0
            emit('showIframe', isIframeVisible)
          }
        }
      }
    }

    observer = new MutationObserver(emitShowIframe)

    if (targetNode) {
      observer.observe(targetNode, config)
    }
  }, 500)
})

onUnmounted(() => {
  if (observer) {
    observer.disconnect()
  }
})
</script>

<template>
  <ProductBuyWithPrimePlaceholder
    v-if="!props.productSku"
    :on-force-selection="onForceSelection"
  />
  <div
    id="amzn-buy-now"
    :data-site-id="BwpSiteId"
    :data-widget-id="BwpWidgetId"
    :data-sku="props.productSku"
  />
</template>

<style scoped></style>
